import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import site from 'data/site';
import trendingTopics from 'data/trendingTopics';
import { Link } from 'gatsby';

const Header = styled.div`
	margin: 0 auto;
`;
const Wrapper = styled.div`
	width: 100%;
	border-top: 3px solid ${theme.colors.base[0]};
	display: grid;
	${theme.media.large} {
		grid-template-areas: '. logo logo topics topics . ' '. tags tags tags tags .';
		grid-template-columns: ${theme.helpers.edge} auto auto 1fr auto ${theme.helpers.edge};
	}
	${theme.media.notlarge} {
		grid-template-areas: 'logo ' ' topics ';
		grid-template-columns: 100%;
	}
	* {
		align-items: center;
		align-self: center;
		align-content: center;
	}
`;
const Top = styled.div`
	width: 100%;
	height:100%;
	background: ${theme.colors.background[0]};
	${theme.media.large} {
		grid-area: 1 / 1 / span 1 / span 6;
	}
	${theme.media.notlarge} {
		grid-area: 1 / 1 / span 1 / span 1;
	}
}`;
const Bot = styled.div`
	width: 100%;
	height:100%;
	background: ${theme.colors.foreground[0]};
	${theme.media.large} {
		grid-area: 2 / 1 / span 1 / span 6;
	}
	${theme.media.notlarge} {
		grid-area: 2 / 1 / span 1 / span 1;
	}
}`;
const Toggle = styled.div`
	grid-area: toggle;
	display: grid;
	width: 100%;
	height: 100%;
	cursor: pointer;
	color: ${theme.colors.foreground[1]};
	padding: 0 ${theme.size(2)} 0 0;
	font-size: 20px;
	&:hover {
		color: ${theme.colors.foreground[0]};
	}
	display: none;
`;
const Logo = styled.a`
	grid-area: logo;
	display: grid;
	grid-template-columns: auto auto;
	padding: 0 ${theme.size(2)};
	padding-left: 0;
	font-size: 21px;
	color: ${theme.colors.foreground[1]};
	cursor: pointer;
	${theme.media.large} {
		width: 100%;
		height: 100%;
	}
	${theme.media.notlarge} {
		width: auto;
		margin: 0 auto;
		height: ${theme.size(9)};
	}
	span:first-of-type {
		color: ${theme.colors.base[0]};
		font-family: ${theme.font.head};
		font-weight: bold;
	}
	span:last-of-type {
		color: ${theme.colors.foreground[0]};
		opacity: 0.25;
		font-family: ${theme.font.body};
		font-weight: normal;
	}
	&:hover {
		opacity: 0.8;
	}
`;
const Topics = styled.div`
	grid-area: topics;
	width: 100%;
	overflow: hidden;
	padding: 0 ${theme.size(2)};
	text-align: right;
	${theme.media.large} {
		${theme.type.p1};
		font-weight: bold;
		font-size: 0.9em;
		height: ${theme.size(11)};
	}
	${theme.media.notlarge} {
		${theme.type.p2};
		font-weight: normal;
		color: ${theme.colors.background[2]};
		overflow-x: auto;
		height: ${theme.size(7)};
		white-space: nowrap;
	}
	a {
		display: inline-grid;
		height: 100%;
		align-content: center;
		text-transform: capitalize;
		text-transform: uppercase;
		${theme.media.large} {
			margin-left: ${theme.size(4)};
		}
		${theme.media.notlarge} {
			margin-right: ${theme.size(4)};
		}
	}
`;
const Tags = styled.div`
	grid-area: tags;
	width: 100%;
	height: ${theme.size(7)};
	${theme.type.p2};
	overflow: hidden;
	${theme.media.notlarge} {
		display: none;
	}
	a {
		display: inline-grid;
		height: 100%;
		margin-right: ${theme.size(4)};
		align-content: center;
		text-transform: capitalize;
		&:hover {
			color: ${theme.colors.background[0]};
		}
	}
`;

export default props => {
	function handleToggle() {
		console.log('handleToggle');
	}

	return (
		<Header>
			<Wrapper>
				<Top />
				<Bot />
				<Toggle onClick={handleToggle}>☰</Toggle>
				<Logo href="/">
					{site.text.logo.title.split('.').map((item, i) => (
						<span key={i}>
							{i > 0 && '.'}
							{item}
						</span>
					))}
				</Logo>
				<Topics>
					{site.nav.map((item, i) => (
						<a href={item.slug} key={i}>
							{item.title}
						</a>
					))}
				</Topics>
				<Tags>
					{trendingTopics.map((item, i) => (
						<Link to={item.slug} key={i}>
							{item.title}
						</Link>
					))}
				</Tags>
			</Wrapper>
		</Header>
	);
};
