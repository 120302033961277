import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import theme from 'styles/theme';
import LazyLoad from 'react-lazy-load';

const Item = styled.div`
	position: relative;
	display: grid;
	${props => props.layout === 'horizontal' && 'grid-template-columns:1fr 2fr;'}
	${props => props.layout === 'vertical' && 'grid-template-rows:auto 1fr;'}
	grid-gap:${theme.size(2)};
`;
const Image = styled.div`
	font-size: 0;
	img {
		width: 100%;
		border-radius: ${theme.size(0.5)};
	}
`;
const Text = styled.div``;
const Byline = styled.div`
	${theme.type.p2};
	text-transform: uppercase;
	margin-bottom: ${theme.size(1)};
	> * {
		display: inline-block;
		margin-right: ${theme.size(2)};
		&:first-of-type {
			font-weight: bold;
		}
	}
`;
const Category = styled.div`
	color: ${theme.colors.base[0]};
`;
const Title = styled.div`
	${theme.type.p1};
	font-weight: bold;
	margin-bottom: ${theme.size(1)};
	&:hover {
		color: ${theme.colors.base[0]};
	}
`;

function renderImg(props) {
	return <img alt={props.data.title} src={props.data.cover} />;
}

export default props => {
	const lazyload = typeof props.lazyload == 'undefined' ? true : props.lazyload;
	return (
		<Link to={`/${props.data.slug}`}>
			<Item id={`item-${props.data.id}`} layout={props.layout}>
				<Image className="image">
					{lazyload ? (
						<LazyLoad offset={150} debounce={false}>
							{renderImg(props)}
						</LazyLoad>
					) : (
						renderImg(props)
					)}
				</Image>
				<Text>
					<Byline className="byline">
						<Category className="category">{props.data.category}</Category>
					</Byline>
					<Title className="title">{props.data.title}</Title>
				</Text>
			</Item>
		</Link>
	);
};
