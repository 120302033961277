import React, { useEffect, useState } from 'react';
import cookie from 'react-cookies';

export default (name, id, vars) => {
	let [variation, setVariation] = useState(false);

	useEffect(() => {
		const cookieName = `go_exp_${id}`;
		if (typeof cookie.load(cookieName) !== 'undefined') {
			setVariation(Number(cookie.load(cookieName)));
		} else {
			var randomIndex = Math.floor(Math.random() * vars.length);
			cookie.save(cookieName, randomIndex, { path: '/' });
			setVariation(randomIndex);
		}
		window._exps = window._exps || {};
		window._exps[id] = Number(cookie.load(cookieName));

		let GOLogIteration = 0;
		let GOLogInterval = GOLogInterval || {};
		GOLogInterval[id] = setInterval(function() {
			if (typeof window.ga !== 'undefined') {
				clearInterval(GOLogInterval[id]);
				window.ga('set', 'exp', `${id}.${cookie.load(cookieName)}`);
				console.log(`--- EXP ${id}:${cookie.load(cookieName)} (${name})`);
				if (typeof window.googletag != 'undefined') {
					window.googletag.pubads().setTargeting(`EXP:${id}`.substr(0, 19), `${window._exps[id]}`);
					console.log(`--- setTargeting`, `EXP:${id}`.substr(0, 19), `${window._exps[id]}`);
					function getParameterByName(name, url) {
						if (!url) url = window.location.href;
						name = name.replace(/[\[\]]/g, '\\$&');
						var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
							results = regex.exec(url);
						if (!results) return null;
						if (!results[2]) return '';
						return decodeURIComponent(results[2].replace(/\+/g, ' '));
					}
					const params = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'item_id'];
					params.map(item => {
						if (getParameterByName(item)) {
							window.googletag.pubads().setTargeting(item, getParameterByName(item));
							console.log(`--- setTargeting`, item, getParameterByName(item));
						}
					});
				}
			} else {
				GOLogIteration++;
			}
			if (GOLogIteration > 5) {
				clearInterval(GOLogInterval[id]);
				console.log(`--- EXP ${id} (${name}) timeout`);
			}
		}, 1500);
	}, []);

	if (variation === false) {
		return <>{`<!-- EXP:${id} -->`}</>;
	} else {
		return <>{vars[variation]}</>;
	}
};
