import Ad from 'components/Ad';
import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';

export default props => {
	if (typeof window != 'undefined') {
		clearTimeout(window.stickyTimeout);
		window.stickyTimeout = setTimeout(function() {
			Array.from(document.querySelectorAll('.stickyTimeout')).map(item => {
				item.classList.remove('stickyTimeout');
				return true;
			});
		}, 3000);
	}

	const TopAd = styled.div`
		background: ${theme.colors.background[0]};
		padding: ${theme.size(4)};
		margin-bottom: ${theme.size(4)};
		// &.stickyTimeout {
		// 	${theme.media.large} {
		// 		position: sticky;
		// 		top: 0;
		// 		z-index: 99;
		// 	}
		// }
	`;
	return (
		<TopAd className={'stickyTimeout'}>
			<Ad type="leaderboard_top" />
			<Ad type="mpu_1" />
		</TopAd>
	);
};
