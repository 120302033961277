import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import StickyBox from 'react-sticky-box';
import Ad from 'components/Ad';
import List from 'components/List';
import allPosts from 'data/allPostsMin';
import shuffle from 'utils/shuffle';

const Sidebar = styled.div`
	height: 100%;
	${theme.media.large} {
		width: calc(300px + ${theme.size(4)} * 2);
	}
	${theme.media.notlarge} {
		width: 100%;
	}
`;
const Wrapper = styled.div`
	position: relative;
	display: grid;
	background: ${theme.colors.background[0]};
	border-radius: ${theme.size(0.5)};
	overflow: hidden;
	padding: ${theme.size(4)} 0;
`;

export default props => {
	let sidebarallPosts = allPosts;
	shuffle(sidebarallPosts);
	return (
		<Sidebar>
			<StickyBox
				bottom={true}
				offsetTop={Number(theme.size(4).replace('px', ''))}
				offsetBottom={Number(theme.size(4).replace('px', ''))}
			>
				<Wrapper>
					<Ad type="siderail_1" />
					<div id="sidebarRecommendations">
						<List data={sidebarallPosts.slice(0, 2)} />
					</div>
					<Ad type="siderail_2" />
				</Wrapper>
			</StickyBox>
		</Sidebar>
	);
};
