import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import Ad from 'components/Ad';
import Feed from 'components/Feed';
import Feature from 'components/Feature';
import allPosts from 'data/allPostsMin';

const HomeFeed = styled.div`
	display: grid;
	grid-gap: ${theme.size(2)};
	& > * {
		background: ${theme.colors.background[0]};
		border-radius: ${theme.size(0.5)};
	}
`;

export default props => {
	let homeallPosts = allPosts;
	return (
		<HomeFeed>
			<Feature data={homeallPosts.slice(0, 3)} />
			<div>
				<Ad type="leaderboard_bot" />
				<Ad type="mpu_2" />
			</div>
			<div className="feed">
				<Feed data={homeallPosts.slice(3, 9)} />
				<Ad type="leaderboard_bot_2" />
				<Ad type="mpu_3" />

				<Feed data={homeallPosts.slice(9, 15)} />
				<Ad type="leaderboard_bot_3" />
				<Ad type="mpu_4" />

				<Feed data={homeallPosts.slice(15, 21)} />
				<Ad type="leaderboard_bot_4" />
				<Ad type="mpu_5" />

				<Feed data={homeallPosts.slice(21, 27)} />
				<Ad type="leaderboard_bot_5" />
				<Feed data={homeallPosts.slice(27, 33)} />
			</div>
		</HomeFeed>
	);
};
